import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { URN_FONTS } from "../../data";

const FontSelect = ({ font, setFont}) => {
  return (
    <Grid style={{ marginTop: '0px' }} container>
      <Grid style={{ minWidth: '204px', marginLeft: '8px' }} item >
        <InputLabel sx={{ fontSize: '11px', color: 'black', fontFamily: 'Inter', fontWeight: 'bold' }}>DRUH PÍSMA</InputLabel>
        <FormControl style={{ height: '40px' }} size='small' fullWidth >
          <Select value={font} onChange={(e) => setFont(e.target.value)} >
            {URN_FONTS.map((item) => (
              <MenuItem key={item.key} value={item.font}>
                <Typography sx={{ fontFamily: item.font }}>
                  {item.key}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl> 
      </Grid>
    </Grid>
  )
}

export default FontSelect;