import { useState } from "react";

export function useHistoryState(key, initialValue) {
  const [rawState, rawSetState] = useState(() => {
    const value = window.history.state && window.history.state[key];
    return value ?? initialValue;
  });
  function setState(value) {
    window.history.replaceState(
      { ...window.history.state, [key]: value },
      document.title
    );
    rawSetState(value);
  }
  return [rawState, setState];
}