import { Grid } from "@mui/material";
import FieldItem from "./form-field";
import { PhoneInputField } from "./phone-input-field";

const CustomerForm = ({ errors, control}) => {
  return (
    <>
      <Grid style={{ marginTop: '0px' }} container spacing={2}>
        <FieldItem errors={errors} xs={5} label="JMÉNO" name="firstName" control={control}  />
        <FieldItem errors={errors} xs={5} label="PŘÍJMENÍ" name="lastName" control={control} />
        <FieldItem errors={errors} xs={5} label="EMAIL" name="email" control={control} />
        <PhoneInputField errors={errors} control={control} />
      </Grid>
    </>
  )
}

export default CustomerForm;