import { FormControlLabel, Radio, RadioGroup, styled, Typography } from "@mui/material";
import React from "react";

const FormControlLabelRoot = styled(FormControlLabel)(({ value }, currentValue) => { return {
  border: "2px solid white",
  borderRadius: "25px",
  margin: '8px',
  width: '77px',
  height: '130px',
  ...(currentValue === value && {
    background: `linear-gradient(0deg, #B6B6B6 0%, rgba(217, 217, 217, 0) 100%)`,
  })
}});

const label = (imgSrc, altText="") => (
  <div style={{ height: '80px', display: 'table-cell', verticalAlign: 'middle' }}>
    {imgSrc &&
      <img style={{ maxWidth: '60px', maxHeight: '78px' }} src={imgSrc} />
    }
    {!imgSrc &&
      <Typography style={{ padding: '0px 8px 24px 8px', verticalAlign: 'middle', display: 'inline-block',  maxWidth: '180px', maxHeight: '200px', height: '70px' }} variant='h5'>{altText}</Typography>
    }
  </div>
);

const SelectGroup = ({ srcData, currentValue, onChange, altText, hideRadio = false }) => {
  return (
    <RadioGroup value={currentValue} onChange={onChange} row>
      {srcData.map((item) => (
        <FormControlLabelRoot key={item.key} value={item.key} control={(hideRadio ? <div style={{ paddingBottom: '16px' }}></div> : <Radio style={{ padding: '0px 0px 6px 0px'}} disableRipple/>)} labelPlacement="top" label={label(item.src, altText)} />
      ))}
    </RadioGroup>
  )  
}

export default SelectGroup;