import { styled } from "@mui/material";
import ColorStep from "./color-step";
import MaterialStep from "./material-step";
import MotiveStep from "./motive-step";
import TextStep from "./text-step";

const MaterialStepStyled = styled('div')(({ theme }) => ({
  marginRight: '4px',
  flexGrow: 1,
  // @ts-ignore
  [theme.breakpoints.up('toColumn')]: {
    // paddingRight: '52px'
    // width: '368px',
    minWidth: '368px',
    // maxWidth: '368px',
  },
}));

const ConfigureStep = ({ birthDate, setBirthDate, burialDate, setBurialDate, font, setFont, fontSizes, setFontSizes, fonts, setFonts, names, setNames, material, setMaterial, art, setArt, artColor, setArtColor }) => {

  return (
    <>
      <div style={{ flexWrap: 'wrap', display: 'flex', width: '100%' }}>
        <MaterialStepStyled>
          <MaterialStep material={material} setMaterial={setMaterial} setArtColor={setArtColor} />
        </MaterialStepStyled>
        <div style={{ flexGrow: 1, minWidth: '248px', marginRight: '4px' }}>
          <ColorStep material={material} artColor={artColor} setArtColor={setArtColor} />
        </div>
      </div>

      <div style={{ minWidth: '200px', maxWidth: '620px', marginRight: '4px' }}>
        <MotiveStep art={art} setArt={setArt} />
      </div>
      <div style={{ maxWidth: '620px', marginRight: '4px' }}>
        <TextStep birthDate={birthDate} setBirthDate={setBirthDate} burialDate={burialDate} setBurialDate={setBurialDate} font={font} setFont={setFont} fontSizes={fontSizes} setFontSizes={setFontSizes} fonts={fonts} setFonts={setFonts} name1={names[0]} name2={names[1]} name3={names[2]} setName1={setNames[0]} setName2={setNames[1]} setName3={setNames[2]} />
      </div>
    </>
  )
}

export default ConfigureStep;