import React from "react";
import { ART_COLORS, URN_MATERIALS } from "../../../data";
import { getItem } from "../../../utils";
import SelectButton from "../select-button";
import StepHeader from "../step-header";

const ColorStep = ({ material, artColor, setArtColor }) => {
  const onChange = (e) => setArtColor(e.target.value);

  const colors = getItem(material, URN_MATERIALS).artColors;
  const colorStruct = colors.map((color) => {
    return ART_COLORS.find((item) => item.key === color)
  });

  return (
    <div style={{ marginBottom: '16px', width: '100%' }}>
      <StepHeader headline='BARVA' />
      <SelectButton onChange={onChange} srcData={colorStruct} currentValue={artColor} altText='BEZ BARVY' />
    </div>
  )
}

export default React.memo(ColorStep);