import AppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar';
import { NavList } from '../navlist';

const Header = ({ activeStep }) => {
  return (
    <AppBar sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} elevation={0} position='static'>
      <Container maxWidth='x1'>
        <Toolbar sx={{ maxWidth: '1430px', mx: "auto" }} disableGutters>
          <div style={{ alignItems: "center", display: "flex", paddingLeft: '64px', marginRight: 'auto', overflow: 'hidden', width: '320px'}}>
            <img src='./assets/logo.svg' alt="Logo" style={{ height: '33px' }} />
          </div>
          <NavList />
          {/*<AppStepper activeStep={activeStep} />*/}
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Header;