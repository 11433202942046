// @ts-nocheck
import { Grid, InputLabel, TextField } from "@mui/material"
import { forwardRef } from "react"
import { Controller } from "react-hook-form";
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import Input from 'react-phone-number-input/input';

const PhoneInput = forwardRef((props, ref) => {
  return (
    <TextField
      {...props}
      inputRef={ref}
      fullWidth
      name={props.name}
      variant="filled"
      error={!!props.errors[props.name]} helperText={props.helperText}
    />
  )
})

const phoneInputRender = ({ errors, field: { onChange, value } }) => {
  const sx = { input: { fontWeight: 'bold', fontSize: '16px', color: 'black', fontFamily: 'Inter', paddingTop: '8px' }};

  return (
    <Input
      onChange={onChange}
      value={value}
      defaultCountry="CZ"
      id="phone"
      inputComponent={PhoneInput}
      name="tel"
      errors={errors}
      sx={sx}
  />
  )
};

export const PhoneInputField = ({ control, errors }) => {
  const handleValidate = (value) => {
    const isValid = isPossiblePhoneNumber(value, 'CZ');

    // check if the length of the phone number does not exceed 15 characters
    const isCorrectLength = value.replace(/\D/g,'').length <= 15;

    return isValid && isCorrectLength;
  }

  return (
    <Grid style={{ marginLeft: '8px' }} item xs={5}>
      <InputLabel sx={{ fontSize: '11px', color: 'black', fontFamily: 'Inter', fontWeight: 'bold' }}>
        TELEFON
      </InputLabel>
      <Controller
        name="tel"
        control={control}
        rules={{
          validate: (value) => handleValidate(value)
        }}
        render={(props) => phoneInputRender({ errors: errors, ...props })}
      />
    </Grid>
  )
}