import React, {  useState } from 'react';
import { Button,  Typography } from "@mui/material";
import StepHeader from "../step-header";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { createOrder } from '../../../utils';

const OverviewStep = ({ orderShippingType, orderProcessingType, orderStatus, setOrderStatus, ctx }) => {
  const [open, setOpen] = useState(true);

  if (orderStatus === 'ORDER_CREATED') {
    return (
      <div style={{ marginBottom: '16px', width: '100%' }}>
        <StepHeader headline='SHRNUTÍ' />
        <Typography style={{ paddingTop: '32px' }} variant='h4'>DĚKUJEME ZA VAŠÍ OBJEDNÁVKU</Typography>
      </div>
    )
  } else if (orderStatus === 'ORDER_FAILED') {
    return (
      <div style={{ marginBottom: '16px', width: '100%' }}>
        <StepHeader headline='SHRNUTÍ' />
        <Typography style={{ paddingTop: '32px' }} variant='h4'>OBJEDNÁVKA SELHALA</Typography>
        <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          OBJEDNÁVKA SELHALA
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Opakujte objednávku později.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>OK</Button>

        </DialogActions>
      </Dialog>
      </div>
    )
  } else if (orderStatus === 'ORDER_NOT_CREATED_YET') {
    createOrder({...ctx, order_processing_type: orderProcessingType, order_shipping_type: orderShippingType, }, setOrderStatus);
    setOrderStatus('ORDER_CREATING')
  }

  return (
    <div style={{ marginBottom: '16px', width: '100%' }}>
      <StepHeader headline='SHRNUTÍ' />
      <Typography style={{ paddingTop: '32px' }} variant='h4'>ZPRACOVÁNÍ...</Typography>
    </div>
  )
}

export default OverviewStep;