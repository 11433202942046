import { Button, Grid } from "@mui/material";
import FieldItem from "./form-field";

const ShippingForm = ({ handleSubmit, errors, control, setUserInfo, setActiveStep, submitRef }) => {
  return (
    <form onSubmit={handleSubmit((d) => {setUserInfo(d); setActiveStep(prevActiveStep => prevActiveStep + 1);})} style={{ width: '100%' }}>
      <Grid style={{ marginTop: '0px' }} container spacing={2}>
        <FieldItem errors={errors} xs={5} label="JMÉNO (pokud se liší)" name="shippingFirstName" control={control}  />
        <FieldItem errors={errors} xs={5} label="PŘÍJMENÍ (pokud se liší)" name="shippingLastName" control={control} />
        <FieldItem errors={errors} xs={5} label="ULICE" name="street" control={control} />
        <FieldItem errors={errors} xs={5} label="MĚSTO" name="city" control={control} />
        <FieldItem errors={errors} xs={5} label="PSČ" name="postal" control={control} />
      </Grid>
      <Button ref={submitRef} sx={{ display: 'none' }} type="submit" />
    </form>
  )
}

export default ShippingForm;