import FontFaceObserver from "fontfaceobserver";

class Font {
  constructor(setCanvasElement, canvasRef) {
    this.setCanvasElement = setCanvasElement;
    this.canvasRef = canvasRef;
  }

  loadFont = (font, setFontsReady) => {
    const ffo = new FontFaceObserver(font);
    const fontLoadingTimeout = 60000; // 5 seconds
    const fontLoadPromise = ffo.load(null, fontLoadingTimeout);

    fontLoadPromise.then(() => {
      setFontsReady(true);
    }).catch((e) => {
      console.log("Font loading error or timeout:", e);
      alert('Font loading failed or timed out for ' + font);
    });
  }

  applyFont = (font, textObjs, setFontsReady) => {
    console.log("Font:", font)
    textObjs.forEach((item) => {
      item.set("fontFamily", font);
    });
  }
}

export default Font;