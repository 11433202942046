import { Button, styled } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import ConfigureStep from './steps/configure-step';
import AddressStep from './steps/address-step';
import OverviewStep from './steps/overview-step';

const ButtonRoot = styled(Button)`
  font-size: 20px;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 12px;
  width: 215px;
  align-items: center;
`;

const Sidebar = ({ birthDate, setBirthDate, burialDate, setBurialDate, font, setFont, userInfo, setUserInfo, ctx, orderShippingType, orderProcessingType, setFontSizes, fontSizes, fonts, setFonts, art, setArt, material, setActiveStep, activeStep, artColor, setArtColor, name1, name2, name3, setName1, setName2, setName3, setMaterial, canvasRef }) => {
  const [orderStatus, setOrderStatus] = useState('ORDER_NOT_CREATED_YET');
  const submitRef = useRef();
  const getFormValues = useRef();

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <ConfigureStep birthDate={birthDate} setBirthDate={setBirthDate} burialDate={burialDate} setBurialDate={setBurialDate} font={font} setFont={setFont} names={[name1, name2, name3]} setNames={[setName1, setName2, setName3]} fontSizes={fontSizes} setFontSizes={setFontSizes} fonts={fonts} setFonts={setFonts} art={art} setArt={setArt} artColor={artColor} setArtColor={setArtColor} material={material} setMaterial={setMaterial} />;
      case 1:
        return <AddressStep getFormValues={getFormValues} userInfo={userInfo} setUserInfo={setUserInfo} setActiveStep={setActiveStep} submitRef={submitRef} orderShippingType={orderShippingType[0]} setOrderShippingType={orderShippingType[1]} orderProcessingType={orderProcessingType[0]} setOrderProcessingType={orderProcessingType[1]}/>
      case 2:
        return <OverviewStep orderShippingType={orderShippingType} orderProcessingType={orderProcessingType} orderStatus={orderStatus} setOrderStatus={setOrderStatus} ctx={ctx} userInfo={userInfo} setUserInfo={setUserInfo} setActiveStep={setActiveStep} submitRef={submitRef} orderShippingType={orderShippingType[0]} setOrderShippingType={orderShippingType[1]} orderProcessingType={orderProcessingType[0]} setOrderProcessingType={orderProcessingType[1]}/>
      default:
        return <div>Not Found</div>;
    }
  };

  const handleNext = () => {
    if (activeStep === 1) {
      // @ts-ignore
      submitRef.current.click(); 
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 1) {
      setUserInfo(getFormValues.current.call());
    } 
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };
    
  if (!!canvasRef && !('current' in canvasRef)) {
    return
  }

  return (
    <div style={{ maxWidth: '100%', minWidth: "320px", flex: '1' }}>
      <div style={{ maxWidth: '100%' }}>
        {getStepContent(activeStep)}
      </div>
      {/* <div style={{ minWidth: '558px', width: '558px', justifyContent: 'center',  display: 'flex', flex: 1 }}>
        {/* <div style={{ marginLeft: '8px', marginBottom: '16px' }} >
          <a href={`data:image/svg+xml;utf8,${encodeURIComponent(canvasRef.current.toSVG())}`} download="canvas.svg"><button>Stáhnout</button></a>
        </div> */}
        {orderStatus === 'ORDER_NOT_CREATED_YET'  && 
          <div style={{ justifyContent: 'center', display: 'flex', paddingTop: '32px' }} >
            <ButtonRoot disableRipple disableElevation disabled={activeStep === 0} size="large" variant="contained" onClick={(e) => {handleBack()}}>UPRAVIT DESIGN</ButtonRoot>
            <ButtonRoot disableRipple disableElevation size="large" variant="contained" onClick={(e) => {handleNext()}}>
              {activeStep === 1 ? 'ZAPLATIT' : 'OBJEDNAT'}
            </ButtonRoot>
          </div>
        }
    </div>
  )
}

export default Sidebar;