import { FormControl, Grid, InputLabel, TextField  } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

const DateSelect = ({ title, date, setDate }) => {

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid style={{ marginTop: '0px' }} container spacing={2}>
        <Grid item >
          <InputLabel sx={{ fontSize: '11px', color: 'black', fontFamily: 'Inter', fontWeight: 'bold' }}>{title}</InputLabel>
          <FormControl fullWidth >
            <DesktopDatePicker
              inputFormat="dd/MM/yyyy"
              renderInput={(params) => <TextField size='small' {...params} />}
              onChange={(date) => setDate(date)}
              value={date}
            />
          </FormControl>
        </Grid>
      </Grid>
    </LocalizationProvider>
  )
}

export default DateSelect;