import { useEffect, useCallback } from 'react';
import { fabric } from 'fabric';
import { DEFAULT_URN_FONT_SIZE } from '../../data';
import { changeMotive, getColor, getFontSize } from '../../utils';

const Canvas = ({ canvasReady, motiveAssets, setCanvasReady, imgObj, font, fontSizes, textObj1, textObj2, textObj3, setTextObj1, setTextObj2, setTextObj3, svgAssets, setImgObj, setCanvasElement, name1, name2, name3, canvasRef, art, artColor }) => {
  const initCanvas = (canvas) => {
    setCanvasReady(false);
    console.log("init canvas");

    initText();
    setCanvasReady(true);
  }

  const initText = () => {
    console.log("init text");

    const text1 = new fabric.Text(
      name1,
      {
        originX: "center",
        top: 155,
        fontSize: getFontSize(DEFAULT_URN_FONT_SIZE),
        width: 50,
        fill: getColor(artColor),
        fontFamily: font
        //textAlign: 'center'
      }
    );

    const text2 = new fabric.Text(
      name2,
      {
        originX: "center",
        top: 185,
        left: 50,
        fontSize: getFontSize(DEFAULT_URN_FONT_SIZE),
        width: 50,
        fill: getColor(artColor),
        fontFamily: font
        //textAlign: 'center'
      }
    );

    const text3 = new fabric.Text(
      name3,
      {
        originX: "center",
        top: 215,
        fontSize: getFontSize(DEFAULT_URN_FONT_SIZE),
        width: 50,
        fill: getColor(artColor),
        fontFamily: font
        //textAlign: 'center'
      }
    );

    console.log("setting textObj1");
    setTextObj1(text1);

    console.log("setting textObj2");
    setTextObj2(text2);

    console.log("setting textObj3");
    setTextObj3(text3);

    text1.set('selectable', false);
    text2.set('selectable', false);
    text3.set('selectable', false);

    canvasRef.current.selection = false;

    canvasRef.current.add(text1);
    canvasRef.current.add(text2);
    canvasRef.current.add(text3);

    text1.centerH();
    text2.centerH();
    text3.centerH();
  }

  const useFabric = (canvas) => {
    const fabricRef = useCallback((element) => {
      if (!element) return canvas.current?.dispose();

      canvas.current = new fabric.Canvas(element, { backgroundColor: null });
      initCanvas(canvas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return fabricRef;
  };

  const ref = useFabric(canvasRef);

  // urn text content
  useEffect(() => {
    if (textObj1) {
      textObj1.text = name1;
      if (!name1) {
        textObj2.top = 155
        textObj3.top = 185
      } else {
        if (name2) {
          textObj2.top = 185
          textObj3.top = 215
        } else {
          textObj2.top = 185
          textObj3.top = 185
        }
      }
      if (canvasRef.current) {
        console.log(textObj1)
        textObj1.centerH();

        canvasRef.current.renderAll();
      }
    }
  }, [name1]);

  useEffect(() => {
    if (textObj2) {
      textObj2.text = name2;
      if (!name2) {
        textObj3.top = 185
      } else {
        if (name1) {
          textObj3.top = 215
        } else {
          textObj3.top = 185
        }
      }
      if (canvasRef.current) {
        console.log(textObj2)
        textObj2.centerH();

        canvasRef.current.renderAll();
      }
    }
  }, [name2]);

  useEffect(() => {
    if (textObj3) {
      textObj3.text = name3;
      if (canvasRef.current) {
        console.log(textObj3)
        textObj3.centerH();

        canvasRef.current.renderAll();
      }
    }
    setCanvasElement(canvasRef.current.toDataURL());
  }, [name3]);

  // urn text font size
  useEffect(() => {
    if (textObj1 && textObj2 && textObj3) {
      textObj1.set("fontSize", getFontSize(fontSizes[0]));
      textObj2.set("fontSize", getFontSize(fontSizes[1]));
      textObj3.set("fontSize", getFontSize(fontSizes[2]));
      canvasRef.current.renderAll();
      setCanvasElement(canvasRef.current.toDataURL());
    }
  }, [fontSizes]);

  // urn motive color
  useEffect(() => {
    if (textObj1 && textObj2 && textObj3) {
      console.log("changing color", artColor);
      textObj1.set({ fill: getColor(artColor) });
      textObj2.set({ fill: getColor(artColor) });
      textObj3.set({ fill: getColor(artColor) });
    }

    if (!!imgObj && "_objects" in imgObj) {
      imgObj._objects.forEach((item) => { item.set({ fill: getColor(artColor) }) });
    } else if (!!imgObj && "fill" in imgObj) {
      // corner case for groupless svg assets
      imgObj.set({ fill: getColor(artColor) });
    }

    if (canvasRef.current) {
      canvasRef.current.renderAll();
      setCanvasElement(canvasRef.current.toDataURL());
    }
  }, [artColor]);

  // urn motive
  useEffect(() => {
    if (Object.keys(svgAssets).length === motiveAssets.length) {
      changeMotive(art, imgObj, canvasRef, setImgObj, artColor, svgAssets);
      canvasRef.current.renderAll();
      setCanvasElement(canvasRef.current.toDataURL());
    }
  }, [art]);

  return (
    <div style={{ justifyContent: 'center', alignItems: 'center', display: 'none' }}>
      <div className="Canvas" style={{ width: '450px', height: '300px', backgroundColor: 'white', marginBottom: '16px', display: 'flex', justifyContent: 'center' }}>
        <canvas style={{ border: '1px solid black' }} ref={ref} width={450} height={300} />
      </div>
    </div>
  )
}

export default Canvas;