import React, { useCallback, useEffect } from "react";
import { URN_MOTIVES } from "../../../data";
import SelectButton from "../select-button";
import StepHeader from "../step-header";

const MotiveStep = ({ art, setArt }) => {
  const onChange = (e) => setArt(e.target.value);

  return (
    <div style={{ marginBottom: '16px', width: '100%' }}>
      <StepHeader headline='MOTIV' />
      <SelectButton onChange={onChange} srcData={URN_MOTIVES} currentValue={art} altText='BEZ MOTIVU' />
    </div>
  )
}

export default React.memo(MotiveStep);