import { Grid, styled } from "@mui/material";
import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { Privacy } from "../routes/privacy";
import { Terms } from "../routes/terms";
import Canvas from "./canvas/canvas";
import Preview from "./canvas/preview";
import Sidebar from "./sidebar";

const CanvasStyled = styled(Grid)(({ theme }) => ({
  // @ts-ignore
  [theme.breakpoints.down('mobile')]: {
    maxWidth: "100%",
    // transform: "scale(0.5, 0.5)",
    // marginTop: '-100px'
  },
}));

const SidebarStyled = styled(Grid)(({ theme }) => ({
  overflowY: "auto",
  minHeight: 'calc(100vh - 56px)',
  paddingLeft: '32px',
  // @ts-ignore
  [theme.breakpoints.down('mobile')]: {
    // marginTop: '-32px',
    // transform: "scale(0.8, 0.8)",
    justifyContent: "center",
    display: 'flex',
    paddingLeft: '16px',
    paddingRight: '16px',
    maxWidth: "100%",
  },
}));

const SideBySideStyled = styled(Grid)(({ theme }) => ({
  justifyContent: "center",
  // @ts-ignore
  [theme.breakpoints.down('mobile')]: {
    // display: "flex",
  },
}));

export const Router = ({ canvasReady, motiveAssets, setCanvasReady, imgObj, fontsReady, birthDate, setBirthDate, burialDate, setBurialDate, font, setFont, userInfo, setUserInfo, ctx, orderShippingType, orderProcessingType, setFontSizes, fontSizes, fonts, setFonts, art, setArt, material, setActiveStep, activeStep, artColor, setArtColor, setName1, setName2, setName3, setMaterial, previewRef, canvasElement, textObj1, textObj2, textObj3, setTextObj1, setTextObj2, setTextObj3, svgAssets, setImgObj, setCanvasElement, name1, name2, name3, canvasRef }) => {
  return (
      <Routes>
        <Route path="/" element={
          <React.Fragment>
            <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <SideBySideStyled container>
                <CanvasStyled>
                  <div style={{ alignItems: 'center', flex: '1' }}>
                    {!!fontsReady && <Canvas canvasReady={canvasReady} motiveAssets={motiveAssets} setCanvasReady={setCanvasReady} art={art} setImgObj={setImgObj} imgObj={imgObj} font={font} fontSizes={fontSizes} setTextObj1={setTextObj1} setTextObj2={setTextObj2} setTextObj3={setTextObj3} textObj1={textObj1} textObj2={textObj2} textObj3={textObj3} svgAssets={svgAssets} setCanvasElement={setCanvasElement} name1={name1} name2={name2} name3={name3} canvasRef={canvasRef} artColor={artColor} />}
                    {!!canvasElement && <Preview material={material} canvasElement={canvasElement} previewRef={previewRef} />}
                  </div>
                </CanvasStyled>
                <SidebarStyled>
                  <Sidebar birthDate={birthDate} setBirthDate={setBirthDate} burialDate={burialDate} setBurialDate={setBurialDate} font={font} setFont={setFont} userInfo={userInfo} setUserInfo={setUserInfo} ctx={{...ctx, ...userInfo}} orderShippingType={orderShippingType} orderProcessingType={orderProcessingType} setFontSizes={setFontSizes} fontSizes={fontSizes} fonts={fonts} setFonts={setFonts} art={art} setArt={setArt} material={material} setActiveStep={setActiveStep} activeStep={activeStep} artColor={artColor} setArtColor={setArtColor} canvasRef={canvasRef} name1={name1} name2={name2} name3={name3} setName1={setName1} setName2={setName2} setName3={setName3} setMaterial={setMaterial} />
                </SidebarStyled>
              </SideBySideStyled>
            </div>
          </React.Fragment>
        } />

        <Route path="/terms" element={
          <React.Fragment>
            <Terms />
          </React.Fragment>
        } />
        
        <Route path="/privacy" element={
          <React.Fragment>
            <Privacy />
          </React.Fragment>
        } />

        <Route
          path="*"
          element={<Navigate to="/" replace />}
        />

      </Routes>
  )
}