import { FormControl, Grid, InputLabel, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

const FieldItem = ({ label, name, control, xs, errors, noMargin=false }) => {
  const sx = { input: { fontWeight: 'bold', fontSize: '16px', color: 'black', fontFamily: 'Inter', paddingTop: '8px' }};
  const helperText = errors[name] ? errors[name].message : '';
  
  return (
    <Grid style={{ marginLeft: noMargin ? '' : '8px' }} item xs={xs}>
      <InputLabel sx={{ fontSize: '11px', color: 'black', fontFamily: 'Inter', fontWeight: 'bold' }}>
        {label}
      </InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControl fullWidth>
            <TextField error={!!errors[name]} helperText={helperText} variant="filled" FormHelperTextProps={{ sx: { fontSize: 14 }}} sx={sx} {...field} />
          </FormControl>
        )}
      />
    </Grid>
  )
}

export default FieldItem;