import { ART_COLORS, URN_FONT_SIZES, URN_MOTIVES } from "./data";
import { API } from 'aws-amplify';
import { format } from 'date-fns';
import _ from "lodash";

export const getItem = (key, dict) => {
  return dict.find((o) => o.key === key);
}

export const getPath = (key, dict) => {
  return dict.find((o) => o.key === key).src;
}

export const getColor = (key) => {
  return ART_COLORS.find((o) => o.key === key).rgb;
}

export const getFontSize = (key) => {
  return URN_FONT_SIZES.find((o) => o.key === key).size;
}

export const createOrder = (ctx, setOrderStatus) => {
  API.post("createOrder", "/createOrder", {
    body: {
      first_name: ctx.firstName,
      last_name: ctx.lastName,
      email: ctx.email,
      tel: ctx.tel,
      street: ctx.street,
      city: ctx.city,
      postal: ctx.postal,
      material: ctx.material,
      art: ctx.art,
      art_color: ctx.artColor,
      text_font: ctx.font,
      text1: ctx.text1,
      text2: ctx.text2,
      text3: ctx.text3,
      font1: ctx.font,
      font2: ctx.font,
      font3: ctx.font,
    }
  }).then(response => {
    setOrderStatus('ORDER_CREATED');
  }).catch(error => {
    setOrderStatus('ORDER_FAILED');
  });
};

export const changeMotive = (motive, imgObj, canvasRef, setImgObj, artColor, svgAssets) => {
  console.log("changing img", motive);

  if (motive === "none") {
    canvasRef.current.remove(imgObj);
    return;
  }

  if (!!imgObj) canvasRef.current.remove(imgObj);

  const img = _.cloneDeep(svgAssets[getPath(motive, URN_MOTIVES)]);
  img.set('selectable', false);

  if ('_objects' in img) {
    img._objects.forEach((item) => { item.set({ fill: getColor(artColor) }) });
  } else if ('fill' in img) {
    img.set({ fill: getColor(artColor) });
  }

  canvasRef.current.add(img);
  canvasRef.current.centerObject(img);
  img.top = getItem(motive, URN_MOTIVES).top;

  setImgObj(img);
};

export const parseBirthAndBurialDate = (birthDate, burialDate) => {
  if (isNaN(birthDate) || isNaN(burialDate)) {
    return '';
  }
  if (!birthDate || !burialDate) {
    return '';
  }
  const birthDateStr = `${format(birthDate, 'dd.MM.yyyy').replace(/\b0/g, '')}`
  const burialDateStr = `${format(burialDate, 'dd.MM.yyyy').replace(/\b0/g, '')}`
  return `* ${birthDateStr}  † ${burialDateStr}`;
}