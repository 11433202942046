import StepHeader from '../step-header';
import SelectButton from '../select-button';

import { URN_MATERIALS } from '../../../data';
import { getItem } from '../../../utils';
import React from 'react';

const MaterialStep = ({ material, setMaterial, setArtColor }) => {
  const onChange = (e) => {
    const artColor = getItem(e.target.value, URN_MATERIALS).artColors[0];
    setArtColor(artColor);
    setMaterial(e.target.value)
  };

  return (
    <div style={{ marginBottom: '16px', width: '100%' }}>
      <StepHeader headline='MATERIÁL' />
      <SelectButton onChange={onChange} srcData={URN_MATERIALS} currentValue={material} altText='BEZ MOTIVU' />
    </div>
  )
}

export default React.memo(MaterialStep);