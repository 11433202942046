import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  breakpoints: {
    values: {
      // @ts-ignore
      mobile: 1116,
      toColumn: 556,
      headerBreakpoint: 948,
      previewBreakpoint: 896,
    }
  },
  typography: {
    body2: {
      fontFamily: 'Inter',
    },
    h4: {
      fontWeight: '700',
    },
    fontFamily: [
      "Bebas Neue",
    ].join(','),
  },
  components: {
    MuiStepLabel: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.active && {
            backgroundColor: 'white',
          }),
        }),
      },
    },
  },
  palette: {
    primary: {
      main: '#1a1c1f'
    }
  }
});

export default theme;