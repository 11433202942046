import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';

const postalRegExp = /^\d{3} ?\d{2}$/

const schema = yup
  .object()
  .shape({
    firstName: yup.string().required('Zadejte jméno'),
    lastName: yup.string().required('Zadejte příjmení'),
    email: yup.string().email().required('Zadejte email'),
    tel: yup.string(),
    shippingFirstName: yup.string(),
    shippingLastName: yup.string(),
    street: yup.string().required('Zadejte ulici'),
    city: yup.string().required('Zadejte město'),
    postal: yup.string().matches(postalRegExp, 'Číslo není platné').required('Zadejte PSČ'),
  })
  .required();

export const Form = (defaultValues) => {
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: defaultValues.firstName,
      lastName: defaultValues.lastName,
      email: defaultValues.email,
      tel: defaultValues.tel,
      shippingFirstName: defaultValues.shippingFirstName,
      shippingLastName: defaultValues.shippingLastName,
      street: defaultValues.street,
      city: defaultValues.city,
      postal: defaultValues.postal,
    }
  });
  return form
}