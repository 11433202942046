import { styled } from '@mui/material';
import { useEffect } from 'react';
import { URN_MATERIALS } from '../../data';
import { getPath } from '../../utils';

const CanvasStyled = styled('canvas')(({ theme }) => ({
  // @ts-ignore
  [theme.breakpoints.up('mobile')]: {
    minWidth: 240,
    minHeight: 350,
    // marginRight: '-36px',
    marginLeft: '-36px',
  },
  
  // @ts-ignore
  [theme.breakpoints.down('mobile')]: {
    minWidth: 240,
    maxHeight: 350,
    marginRight: '129px',
    marginLeft: '129px',
  },
}));

const Preview = ({ previewRef, canvasElement, material }) => {
  const loadUpper = async (ctx, imgBgn) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = canvasElement;
      img.onload = async () => {
        const hRatio = ctx.canvas.width / imgBgn.width;
        const vRatio = ctx.canvas.height / imgBgn.height;
        const ratio  = Math.min(hRatio, vRatio);
        const centerShift_x = (ctx.canvas.width - imgBgn.width*ratio) / 2;
        const centerShift_y = (ctx.canvas.height - imgBgn.height*ratio) / 2;  
        
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.drawImage(imgBgn, 0, 0, imgBgn.width, imgBgn.height, centerShift_x, centerShift_y+30, imgBgn.width*ratio, imgBgn.height*ratio);

        const iw = img.width+100;
        const ih = img.height;
        const xOffset = 65; // left padding
        const yOffset = 160; // top padding

        const a = 180.0; // image width
        const b = 20; // round ness
        const scaleFactor = iw / (3 * a);

        // draw vertical slices
        for (var X = 0; X < iw; X += 1) {
          var y = b / a * Math.sqrt(a * a - (X - a) * (X - a)); // ellipsis equation
          ctx.drawImage(img, X * scaleFactor+25, 0, iw / 20, ih, X + xOffset, y + yOffset, 1, 300);
        }
        resolve(true);
      }
  });
  };

  useEffect(() => {
    const loadImage = async () => {
      return new Promise((resolve, reject) => {
        const canvas = previewRef.current;
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = '#f0f0f0';
        const imgBgn = new Image();
        imgBgn.src = getPath(material, URN_MATERIALS);
        imgBgn.onload = async () => {
          await loadUpper(ctx, imgBgn);
          resolve(true);
        };
    });
  };
  loadImage().catch(console.error);
}, [canvasElement]);

  return (
    <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }} className="Preview">
      <CanvasStyled style={{ minWidth: 240, minHeight: 350 }} ref={previewRef} width={2.0*240} height={2.0*350} />
    </div>
  )
}

export default Preview;