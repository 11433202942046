export const DEFAULT_URN_MATERIAL = "gray"; 
export const URN_MATERIALS = [
  {
    key: "sand",
    src: "./assets/urna_bezova.png",
    artColors: ['brass'],
  },
  {
    key: "gray",
    src: "./assets/urna_bila.png",
    artColors: ['black'],
  },
  {
    key: "anthracite",
    src: "./assets/urna_cerna.png",
    artColors: ['gold', 'silver'],
  }
]

export const DEFAULT_URN_MOTIVE = "art1";
export const URN_MOTIVES = [
  {
    key: "none",
    src: "",
  },
  {
    key: "art1",
    src: "./assets/art1.svg",
    top: 10,
  },
  {
    key: "art2",
    src: "./assets/art2.svg",
    top: 0,
  },
  {
    key: "art3",
    src: "./assets/art3.svg",
    top: 10,
  },
  {
    key: "art4",
    src: "./assets/art4.svg",
    top: 10,
  },
  {
    key: "art5",
    src: "./assets/art5.svg",
    top: 0,
  },
  {
    key: "art6",
    src: "./assets/art6.svg",
    top: 30,
  },
  {
    key: "art7",
    src: "./assets/art7.svg",
    top: 30,
  },
  {
    key: "art8",
    src: "./assets/art8.svg",
    top: 10,
  },
  {
    key: "art9",
    src: "./assets/art9.svg",
    top: 20,
  },
  {
    key: "art10",
    src: "./assets/art10.svg",
    top: 40,
  },
  {
    key: "art11",
    src: "./assets/art11.svg",
    top: 40,
  },
]

export const DEFAULT_URN_COLOR = "black";
export const ART_COLORS = [
  {
    key: "black",
    rgb: "black",
    src: "./assets/black_texture.png",
  },
  {
    key: "gold",
    rgb: "gold",
    src: "./assets/gold_texture.png",
  },
  {
    key: "silver",
    rgb: "silver",
    src: "./assets/silver_texture.png",
  },
  {
    key: "brass",
    rgb: "#cd7f32",
    src: "./assets/bronze_texture.png",
  },
]

export const DEFAULT_URN_FONT = "Source Sans Variable";
export const URN_FONTS = [
  {
    key: "FONT A",
    font: "Source Sans Variable",
  },
  {
    key: "FONT B",
    font: "Source Serif Variable",
  },
  {
    key: "FONT C",
    font: "Unica One",
  },
]

export const DEFAULT_URN_FONT_SIZE = "large";
export const URN_FONT_SIZES = [
  {
    key: "small",
    size: 20,
  },
  {
    key: "medium",
    size: 23,
  },
  {
    key: "large",
    size: 26,
  },
]

export const ORDER_PROCESSING_TYPES = [
  {
    key: "ORDER_PROCESSING_STANDARD",
    label: "STANDARDNÍ",
  },
  {
    key: "ORDER_PROCESSING_EXPRESS",
    label: "EXPRESNÍ DO 3 DNŮ (+900 CZK)",
  },
]

export const ORDER_SHIPPING_TYPES = [
  {
    key: "ORDER_SHIPPING_CP",
    label: "ČESKÁ POŠTA",
  },
  {
    key: "ORDER_SHIPPING_DPD",
    label: "DPD",
  },
]

