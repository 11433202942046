import { IconButton, Menu, MenuItem } from "@mui/material"
import { useState } from "react";
import { NavLink } from "react-router-dom";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const DropdownNavlist = ({ routes }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton size="large" onClick={handleClick}>
        <ArrowDropDownIcon sx={{ fontSize: 32 }} htmlColor="white" />
      </IconButton>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {routes.map((item) => (
          <MenuItem onClick={handleClose}>
            <NavLink key={item.key} to={item.route} style={({ isActive }) => ({ color: isActive ? "white" : "#1a1c1f", backgroundColor: isActive ? "#1a1c1f" : "white", paddingLeft: '16px', paddingRight: '16px', fontSize: 20, textDecoration: 'none' })}>
              {item.title}
            </NavLink>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
