import { Typography } from "@mui/material";

const StepHeader = ({ headline, text=null, width=null }) => {

  return (
    <>
      <div style={{ width: '100%', alignItems: 'center', paddingTop: '24px', display: 'flex' }}>
        <Typography style={{ paddingRight: '16px' }} variant='h4'>{headline}</Typography>
        <div style={{ backgroundColor: 'white', width: width ? width : '100%', height: '4px', marginRight: '16px' }} />
      </div>
      {!!text && 
        <Typography style={{ paddingTop: '16px', color: 'black', width: '480px' }} variant='body2'>{text}</Typography>
      }
    </>
  )
}

export default StepHeader;