import { Grid } from "@mui/material";
import React from "react";
import DateSelect from "../date-select";
import FontSelect from "../font-select";
import StepHeader from "../step-header";
import TextEdit from '../text-edit';

const TextStep = ({ birthDate, setBirthDate, burialDate, setBurialDate, font, setFont, setFontSizes, fontSizes, fonts, setFonts, name1, name2, name3, setName1, setName2, setName3 }) => {
  return (
    <div style={{ marginBottom: '16px', width: '100%' }}>
      <StepHeader headline='TEXT' />

      <Grid style={{ flexWrap: 'wrap', flexDirection: 'column' }} container spacing={1}>
        <Grid style={{ marginTop: '16px' }} item xs={10}>
          <FontSelect font={font} setFont={setFont} />
        </Grid>

        <Grid style={{ justifyContent: 'space-between' }} container spacing={1} >

          <Grid style={{ marginLeft: '8px' }} item xs={5}>
            <TextEdit id="text1" title='PRVNÍ ŘÁDEK (20 ZNAKŮ)' text={name1} setText={setName1} font={fonts[0]} setFont={setFonts[0]} fontSize={fontSizes[0]} setFontSize={setFontSizes[0]} />
            <TextEdit id="text2" title='DRUHÝ ŘÁDEK (20 ZNAKŮ)' text={name2} setText={setName2} font={fonts[1]} setFont={setFonts[1]} fontSize={fontSizes[1]} setFontSize={setFontSizes[1]} />
            {/* <TextEdit title='TŘETÍ ŘÁDEK (20 ZNAKŮ)' text={name3} setText={setName3} font={fonts[2]} setFont={setFonts[2]} fontSize={fontSizes[2]} setFontSize={setFontSizes[2]} /> */}
          </Grid>

          <Grid style={{ marginLeft: '16px'}} item alignItems="center">
            <Grid item xs={5}>
              <DateSelect date={birthDate} setDate={setBirthDate} title='DATUM NAROZENÍ' />
            </Grid>
            <Grid item xs={5}>
              <DateSelect date={burialDate} setDate={setBurialDate}  title='DATUM ÚMRTÍ' />
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </div>
  )
}

export default React.memo(TextStep);