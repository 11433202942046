import { styled, useMediaQuery, useTheme } from "@mui/material";
import { NavLink } from "react-router-dom"
import { DropdownNavlist } from "./dropdown-navlist";

const routes = [
  {
    key: "configure",
    title: "Konfigurace",
    route: "/"
  },
  {
    key: "terms",
    title: "Obchodní podmínky",
    route: "/terms"
  },
  {
    key: "privacy",
    title: "Ochrana osobních údajů",
    route: "/privacy"
  },
];

const NavStyled = styled('nav')(({ theme }) => ({
  display: 'flex',
  paddingRight: '64px',
}));

export const NavList = () => {
  const theme = useTheme();
  // @ts-ignore
  const isMobile = useMediaQuery(theme.breakpoints.down('headerBreakpoint'))

  return (
    <>
      {!!isMobile && (
        <DropdownNavlist routes={routes} />
      )}

      {!isMobile && (
        <NavStyled>
          {routes.map((item) => (
            <NavLink key={item.key} to={item.route} style={({ isActive }) => ({ color: isActive ? "#1a1c1f" : "white", backgroundColor: isActive ? "white" : "#1a1c1f", paddingLeft: '16px', paddingRight: '16px', fontSize: 20, textDecoration: 'none' })}>
              {item.title}
            </NavLink>
          ))}
        </NavStyled>
      )}
    </>
  )
}
