import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { ORDER_PROCESSING_TYPES, ORDER_SHIPPING_TYPES } from "../../../data";
import CustomerForm from "../../form/customer-form";
import ShippingForm from "../../form/shipping-form";
import StepHeader from "../step-header";
import { Form } from "../../form";

const RadioGroupItem = ({ onChange, value, choices }) => (
  <FormControl style={{ marginLeft: '8px' }} >
  <RadioGroup onChange={onChange} value={value} row>
    {choices.map((choice) => (
      <FormControlLabel style={{ minWidth: '170px', paddingRight: '16px' }} label={<Typography style={{ color: 'black', fontSize: '14px', fontWeight: 'bold', fontFamily: 'Inter' }} variant="body2" color="textSecondary">{choice.label}</Typography>} value={choice.key} control={<Radio size='medium' />} />
    ))}
  </RadioGroup>
 </FormControl>
)

const AddressStep = ({ getFormValues, userInfo, setUserInfo, setActiveStep, submitRef, orderShippingType, setOrderShippingType, orderProcessingType, setOrderProcessingType }) => {
  const { getValues, control, handleSubmit, formState: { errors } } = Form(userInfo);

  getFormValues.current = getValues;

  return (
    <div style={{ marginBottom: '16px', width: '100%', paddingRight: '4px' }}>
      <div style={{ maxWidth: '620px' }}>
        <StepHeader headline='ZÁKAZNÍK' />
        <CustomerForm errors={errors} control={control} />
      </div>

      <div style={{ maxWidth: '620px' }}>
        <StepHeader headline='ADRESA' />
        <ShippingForm errors={errors} control={control} handleSubmit={handleSubmit} setUserInfo={setUserInfo} setActiveStep={setActiveStep} submitRef={submitRef} />
      </div>

      <div style={{ maxWidth: '620px' }}>
        <StepHeader headline='ZPRACOVÁNÍ' />
        <RadioGroupItem onChange={(e) => setOrderProcessingType(e.target.value)} value={orderProcessingType} choices={ORDER_PROCESSING_TYPES} />
      </div>

      <div style={{ maxWidth: '620px' }}>
        <StepHeader headline='DOPRAVA' />
        <RadioGroupItem onChange={(e) => setOrderShippingType(e.target.value)} value={orderShippingType} choices={ORDER_SHIPPING_TYPES} />
      </div>
    </div>
  )
}

export default AddressStep;