import { FormControl, Grid, InputLabel, TextField  } from "@mui/material";

const TextEdit = ({ id, title, text, setText, font, setFont, fontSize, setFontSize }) => {

  return (
    <Grid style={{ marginTop: '0px' }} container spacing={2}>
      <Grid style={{ marginLeft: '8px' }} item xs={12}>
        <InputLabel sx={{ fontSize: '11px', color: 'black', fontFamily: 'Inter', fontWeight: 'bold' }}>{title}</InputLabel>
        <FormControl fullWidth >
          <TextField style={{ maxWidth: '204px' }} inputProps={{ maxLength: 20 }} sx={{ input: { fontWeight: 'bold', fontSize: '16px', color: 'black', fontFamily: 'Inter', paddingTop: '8px' }}} value={text} onChange={(e) => setText(e.target.value)} id={id} variant="filled" />
        </FormControl>
      </Grid>

      {/* <Grid style={{ marginLeft: '8px' }} item xs={5}>
        <FormControl fullWidth >
          <InputLabel sx={{ fontSize: '14px', color: 'black', fontFamily: 'Inter', fontWeight: 'bold' }}>VELIKOST PÍSMA</InputLabel>
          <Select value={fontSize} onChange={(e) => setFontSize(e.target.value)} label={(<Typography sx={{ fontSize: '11px', color: 'black', fontFamily: 'Inter', fontWeight: 'bold' }}>VELIKOST PÍSMA</Typography>)}>
            {URN_FONT_SIZES.map(item => (
              <MenuItem value={item.key}>{item.key}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid> */}
      {/* <Grid item xs={5}>
        <FormControl fullWidth >
          <InputLabel sx={{ fontSize: '14px', color: 'black', fontFamily: 'Inter', fontWeight: 'bold' }}>DRUH PÍSMA</InputLabel>
          <Select value={font} onChange={(e) => setFont(e.target.value)} label={(<Typography sx={{ fontSize: '11px', color: 'black', fontFamily: 'Inter', fontWeight: 'bold' }}>DRUH PÍSMA</Typography>)}>
            {URN_FONTS.map((item) => (
              <MenuItem value={item.font}>
                <Typography sx={{ fontFamily: item.font }}>
                  {item.key}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid> */}
   </Grid>
  )
}

export default TextEdit;