import { FormControl, styled } from "@mui/material";
import React from "react";
import SelectGroup from "./select-group";

const FormControlRoot = styled(FormControl)`
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  background-color: #717171;
  border-radius: 20px;
  // border: 6px solid transparent;
  background-clip: content-box;
}
`;

const SelectButton = ({ srcData, currentValue, onChange, altText, hideRadio = false }) => {
  return (
    <FormControlRoot style={{ display: 'flex', overflowY: 'auto', width: '100%', maxHeight: '180px', marginTop: (hideRadio ? '' : '8px') }}>
      <SelectGroup srcData={srcData} currentValue={currentValue} onChange={onChange} altText={altText} hideRadio={false} />
    </FormControlRoot>
  )
}

export default React.memo(SelectButton);