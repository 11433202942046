import { useEffect, useRef, useState } from 'react';
import { fabric } from 'fabric';
import './App.css';
import Header from './components/header';

import { DEFAULT_URN_FONT, DEFAULT_URN_FONT_SIZE, DEFAULT_URN_MATERIAL, DEFAULT_URN_MOTIVE, URN_MOTIVES } from './data';
import { parseBirthAndBurialDate } from './utils';
import Font from './components/sidebar/font';
import { Router } from './components/router';
import { useHistoryState } from './components/hooks/use-history-state';

const App = () => {
  const [fontsReady, setFontsReady] = useState(false);

  const [name1, setName1] = useState("S láskou vzpomínáme");
  const [name2, setName2] = useState("Jméno Příjmení");
  const [name3, setName3] = useState("");

  const [textObj1, setTextObj1] = useState(null);
  const [textObj2, setTextObj2] = useState(null);
  const [textObj3, setTextObj3] = useState(null);

  const [font, setFont] = useHistoryState("font", DEFAULT_URN_FONT);
  const [font1, setFont1] = useState(DEFAULT_URN_FONT);
  const [font2, setFont2] = useState(DEFAULT_URN_FONT);
  const [font3, setFont3] = useState(DEFAULT_URN_FONT);
  
  const [fontSize1, setFontSize1] = useState(DEFAULT_URN_FONT_SIZE);
  const [fontSize2, setFontSize2] = useState(DEFAULT_URN_FONT_SIZE);
  const [fontSize3, setFontSize3] = useState(DEFAULT_URN_FONT_SIZE);

  const [art, setArt] = useHistoryState("art", DEFAULT_URN_MOTIVE);
  const [artColor, setArtColor] = useHistoryState("art_color", "black");

  const [birthDate, setBirthDate] = useState(new Date('1950-09-03'));
  const [burialDate, setBurialDate] = useState(new Date());

  const [userInfo, setUserInfo] = useState({});
  
  const [material, setMaterial] = useHistoryState("material", DEFAULT_URN_MATERIAL);

  const [orderProcessingType, setOrderProcessingType] = useHistoryState("order_processing_type", 'ORDER_PROCESSING_STANDARD');
  
  const [orderShippingType, setOrderShippingType] = useHistoryState("order_shipping_type", 'ORDER_SHIPPING_CP');

  const [activeStep, setActiveStep] = useState(0);

  const [imgObj, setImgObj] = useState(null);

  const [svgAssets, setSvgAssets] = useState({"none": {}});

  const [canvasElement, setCanvasElement] = useState();
  const [canvasReady, setCanvasReady] = useState(false);
  
  const canvasRef = useRef(null);
  const previewRef = useRef(null);

  const motiveAssets = URN_MOTIVES.map(motive => motive.src);

  const fontSystem = new Font(setCanvasElement, canvasRef);

  const ctx = {
    text1: name1,
    text2: name2,
    text3: name3,
    art: art,
    artColor: artColor,
    material: material,
    font: font,
    font1: font,
    font2: font,
    font3: font,
  }

  const loadSvgAssets = (assets) => {
    assets.forEach((item) => {
      if (item === "") return;
      fabric.loadSVGFromURL(item, (objects, options) => {
        console.log("[async] loading img", item);
        const img = fabric.util.groupSVGElements(objects, options);
        setSvgAssets((svgAssets) => ({...svgAssets, [item]: img}));
      });
    })
  };

  // load assets
  useEffect(() => {
    console.log("async loading svg assets");
    loadSvgAssets(motiveAssets);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // load fonts
  useEffect(() => {
    console.log("loading fonts");
    fontSystem.loadFont(DEFAULT_URN_FONT, setFontsReady);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // urn birth and burial date parse 
  useEffect(() => {
    if (birthDate || burialDate) {
      setName3(parseBirthAndBurialDate(birthDate, burialDate));
    } else {
      setName3('');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [birthDate, burialDate]);

  // urn text font
  useEffect(() => {
    if (fontsReady) {
      console.log("apply fonts")
      fontSystem.applyFont(font, [textObj1, textObj2, textObj3], setFontsReady);

      canvasRef.current.renderAll();
      setCanvasElement(canvasRef.current.toDataURL());
    }
  }, [font]);

  if (Object.keys(svgAssets).length !== motiveAssets.length)  {
    return <div>Loading...</div>
  }
  
  return (
    <div className="App" style={{ minHeight: 'calc(100vh - 56px)' }}>
      <Header activeStep={activeStep} />
      {/* {currentStep === 1 && <UserForm ctx={ctx}/>} */}
      <div style={{ background: `url('./assets/bgn.png') no-repeat center center / cover fixed` }}>
        <Router canvasReady={canvasReady} motiveAssets={motiveAssets} setCanvasReady={setCanvasReady} art={art} imgObj={imgObj} fontsReady={fontsReady} birthDate={birthDate} setBirthDate={setBirthDate} burialDate={burialDate} setBurialDate={setBurialDate} font={font} setFont={setFont} userInfo={userInfo} setUserInfo={setUserInfo} ctx={{...ctx, ...userInfo}} orderShippingType={[orderShippingType, setOrderShippingType]} orderProcessingType={[orderProcessingType, setOrderProcessingType]} setFontSizes={[setFontSize1, setFontSize2, setFontSize3]} fontSizes={[fontSize1, fontSize2, fontSize3]} fonts={[font1, font2, font3]} setFonts={[setFont1, setFont2, setFont3]} setArt={setArt} material={material} setActiveStep={setActiveStep} activeStep={activeStep} artColor={artColor} setArtColor={setArtColor} canvasRef={canvasRef} name1={name1} name2={name2} name3={name3} setName1={setName1} setName2={setName2} setName3={setName3} setMaterial={setMaterial} canvasElement={canvasElement} previewRef={previewRef}  setTextObj1={setTextObj1} setTextObj2={setTextObj2} setTextObj3={setTextObj3} textObj1={textObj1} textObj2={textObj2} textObj3={textObj3} svgAssets={svgAssets} setImgObj={setImgObj} setCanvasElement={setCanvasElement}/>
      </div>
    </div>
	)
}

export default App;
